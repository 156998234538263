/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        jQuery(document).ready(function($){

          //hide all inputs except the first one
          $('div.hide_this').not(':eq(0)').hide();

          //functionality for add-file link
          $('a.add_file').on('click', function(e){
            //show by click the first one from hidden inputs
            $('div.hide_this:not(:visible):first').show('fast');
            e.preventDefault();
          });

          //functionality for del-file link
          $('.del_file').on('click', function(){
            //e.preventDefault();
            //var init
            var input_parent = $(this).parent().parent();
            console.log(input_parent);
            //hide by click
            input_parent.hide('fast');
            $(input_parent).find('.file-up').val('');
            $(input_parent).find('.file-name').text('Attach file');
          });
        });

        $(document).ready(function() {

          $('.file-up').change(function() {
            var filename = $(this).val();
            if (filename.substring(3,11) === 'fakepath') {
                filename = filename.substring(12);
            } // Remove c:\fake at beginning from localhost chrome
            $(this).parent().parent().parent().find('.file-name').text(filename);
          });

          $('.nav-icon-btn').click(function(){
            console.log('nav click');
            $(this).toggleClass('open');
            $('nav').toggleClass('open');
            $('body').toggleClass('mobile-nav-open');
          });
        });

            $(window).scroll(function() {
              var scroll = $(window).scrollTop();

              if (scroll >= 10) {
                  $(".top-nav").addClass("scrolled");
              } else {
                  $(".top-nav").removeClass("scrolled");
              }
          });

          $(".enlarge-img").on("click", function() {
               var windowHeight = $(window).height() - 130;
               var productImgURL = $(this).attr('data-img-url');
               var imagePreviewWidthStr = $(this).attr('data-img-width');
               var imagePreviewHeightStr = $(this).attr('data-img-height');
               var imagePreviewCaption = $(this).attr('data-img-caption');

               var imagePreviewWidth = parseInt(imagePreviewWidthStr);
               var imagePreviewHeight = parseInt(imagePreviewHeightStr);

               //var imagePreviewOrientation;
               if(imagePreviewWidth > imagePreviewHeight){
                 $('#imagemodal .modal-dialog').removeClass('portrait');
                 $('#imagemodal .modal-dialog').addClass('landscape');

               } else {
                 $('#imagemodal .modal-dialog').removeClass('landscape');
                 $('#imagemodal .modal-dialog').addClass('portrait');
               }

               $('#imagepreview').attr('src', productImgURL);
               $('#myModalLabel').text(imagePreviewCaption);
               $('#imagemodal').modal('show'); // imagemodal is the id attribute assigned to the bootstrap modal, then i use the show function

          });

          $('.solatrim-trigger').click(function(){
            if($(window).width() < 769){
              $('.solatrim-trigger ul').toggleClass('mob-sub-open');  
            }
          });

          $('.faq-question').click(function(){
            console.log('clicked');
            $(this).toggleClass('faq-open');
            $(this).next('.faq-answer').slideToggle();
          });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $(function() {
          $('.cta-box p').matchHeight({
            byRow: false
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
